<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保养与维护工程记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="BYYWHRQ"
                    label="保养与维护日期"
                    class="from-item-block"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.BYYWHRQ"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="BYYWHDX"
                    label="保养与维护对象"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.BYYWHDX"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in YcysList"
                            :key="`${index}`"
                            :value="item.BM"
                            :label="item.MC"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="BYYWHNR"
                    label="保养与维护内容"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.BYYWHNR"
                        resize="none"
                        type="textarea"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="SSZ" label="实施者" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.SSZ"
                        :disabled="!isEdit"
                        :maxlength="50"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="NF" label="年份" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.NF"
                        type="year"
                        value-format="yyyy"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item prop="YF" label="月份" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.YF"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in yfenumData"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="BZDW"
                    label="日常保养照片"
                    class="from-item-block"
                >
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :maxSize="500"
                        :disabled="!isEdit"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "byywhgcjl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1203",
                dataDic: {
                    glycbtid: this.heritageId,
                    BYYWHRQ: "",
                    BYYWHDX: "",
                    BYYWHNR: "",
                    NF: "",
                    YF: "",
                    SSZ: "",
                },
            },
            rules: {
                BYYWHRQ: [
                    {
                        required: true,
                        message: "请选择保养与维护日期",
                        trigger: "change",
                    },
                ],
                SSZ: [
                    {
                        required: true,
                        message: "请填写实施者",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            YcysList: [],
            yfenumData: [],
        };
    },
    mounted() {
        this.GetYcysData();
        this.GetEnum();
    },
    methods: {
        ...mapActions(["GetYcysList", "getAllEnumInfobytableID"]),
        async GetYcysData() {
            let res = await this.GetYcysList();
            this.YcysList = res.ResultValue || [];
        },
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1203",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "YF") {
                    this.yfenumData = v.dataItems;
                }
            });
        },
    },
};
</script>

<style></style>
